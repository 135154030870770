<template>
    <div>



        <table class="table pricelist table-hover">

            <thead>
                <th>
                    Bezeichnung
                </th>
                <th>
                    von - bis
                </th>
                <th>
                    1. Nacht
                    <br/>
                    <small>inkl. Reinigung und Wäsche</small>
                </th>
                <th>
                    Folgenacht
                </th>

                <!--<th>
                    Preis gilt für
                </th>-->
            </thead>
            <tbody v-for="sg in priceArray"
                v-if="sg.service.type == 'RENT'">

                <!-- <tr class="mainrow"
                    style="display:none;">
                    <td>
                        {{sg.service.name}}
                    </td>
                    <td>
                        <span>&nbsp;</span>
                    </td>
                    <td>
                        <span>&nbsp;</span>
                    </td>
                    <td>
                        <span>&nbsp;</span>
                    </td>

                </tr>-->

                <tr v-for="price in sg.lines"
                    v-if="sg.service.type == 'RENT'"
                    class="subrow">
                    <td class="subinfo">
                        {{price.season}}

                    </td>
                    <td>
                        <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                    </td>
                    <td>
                        {{getCalculatedMandatoryPrice(price.season, price.value, price.fromdate)}}
                    </td>
                    <td>
                        {{getCalculatedPrice(price.value)}}
                    </td>

                    <!--<td>
                        {{getPersons()}}
                    </td>-->

                </tr>

            </tbody>
        </table>



    </div>




</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'seasonprices',
    	props: {
    		pricelist: String,
    		beds: [Number, String]
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			serviceByFinalCleaning: [],
    			serviceByBookingFee: [],
    			serviceByLinen: [],
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;

    		//console.log('Beds:::', this.beds);

    		console.log('this.priceArray::', this.priceArray);

    		//Final Cleaning
    		const priceListFilteredByFinalCleaning = pricelist.filter((filterListItem) => {
    			return filterListItem.service.type && filterListItem.service.type === 'FINALCLEANING' && filterListItem.lines;
    		});

    		//console.log('priceListFilteredByFinalCleaning:::', priceListFilteredByFinalCleaning);

    		const serviceByFinalCleaning = priceListFilteredByFinalCleaning.map((service) => {
    			return {
    				lines: service.lines.map(({
    					calculation, condition, fromdate, tilldate, value
    				}) => {
    					return {
    						calculation, condition, fromdate, tilldate, value
    					}
    				}),
    				serviceName: service.service.name,
    				price: service.price
    			}
    		});
    		//console.log('serviceByFinalCleaning::' + JSON.stringify(serviceByFinalCleaning));
    		this.serviceByFinalCleaning = serviceByFinalCleaning;

    		//Booking Fee
    		const priceListFilteredByBookingFee = pricelist.filter((filterListItem) => {
    			return filterListItem.service.type && filterListItem.service.type === 'BOOKING_FEE' && filterListItem.lines;
    		});

    		//console.log('priceListFilteredByBookingFee:::', priceListFilteredByBookingFee);

    		const serviceByBookingFee = priceListFilteredByBookingFee.map((service) => {
    			return {
    				lines: service.lines.map(({
    					calculation, condition, fromdate, tilldate, value
    				}) => {
    					return {
    						calculation, condition, fromdate, tilldate, value
    					}
    				}),
    				serviceName: service.service.name,
    				price: service.price
    			}
    		});
    		//console.log('serviceByBookingFee::' + JSON.stringify(serviceByBookingFee));
    		this.serviceByBookingFee = serviceByBookingFee;

    		//Linen
    		const priceListFilteredByLinen = pricelist.filter((filterListItem) => {
    			return filterListItem.service.type && filterListItem.service.type === 'LINEN' && filterListItem.lines;
    		});

    		//console.log('priceListFilteredByLinen:::', priceListFilteredByLinen);

    		const serviceByLinen = priceListFilteredByLinen.map((service) => {
    			return {
    				lines: service.lines.map(({
    					calculation, condition, fromdate, tilldate, value
    				}) => {
    					return {
    						calculation, condition, fromdate, tilldate, value
    					}
    				}),
    				serviceName: service.service.name,
    				price: service.price
    			}
    		});
    		//console.log('serviceByLinen::' + JSON.stringify(serviceByLinen));
    		this.serviceByLinen = serviceByLinen;


    	},
    	methods: {

    		getPersons: function() {
    			var pers;
    			const data = this.priceArray;
    			const surcharge = data.find(item => item.service.type === "RENT" && item.service.surcharge && item.guestRange && item.guestRange.min);
    			if (surcharge) {
    				pers = surcharge.guestRange.min - 1;
    				return "bis " + pers + " Pers.";
    			} else {
    				return "bis " + this.beds + " Pers.";
    			}
    		},

    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}
    			return calcPrice;
    		},
    		getCleaningPrice: function(startDate) {

    			if (this.serviceByFinalCleaning[0]) {
    				var data = this.serviceByFinalCleaning[0];
    				if (data.price) {
    					return data.price;
    				} else {
    					//console.log('startDate1::' + startDate);
    					//console.log('startDate2::' + new Date(startDate));
    					var dateToCompare = new Date(startDate);
    					if (data.lines) {
    						for (let i = 0; i < data.lines.length; i++) {

    							var fromdate = new Date(data.lines[i].fromdate);
    							var tilldate = new Date(data.lines[i].tilldate);
    							//console.log('fromDate:::' + fromdate);
    							//console.log('tillDate:::' + tilldate);

    							if (dateToCompare >= fromdate && dateToCompare <= tilldate) {
    								return data.lines[i].value;
    							}
    						}
    					}
    					return 0;
    				}
    			}
    		},
    		getBookingPrice: function(startDate) {

    			if (this.serviceByBookingFee[0]) {
    				var data = this.serviceByBookingFee[0];
    				if (data.price) {
    					return data.price;
    				} else {
    					//console.log('startDate1::' + startDate);
    					//console.log('startDate2::' + new Date(startDate));
    					var dateToCompare = new Date(startDate);
    					if (data.lines) {
    						for (let i = 0; i < data.lines.length; i++) {

    							var fromdate = new Date(data.lines[i].fromdate);
    							var tilldate = new Date(data.lines[i].tilldate);
    							//console.log('fromDate:::' + fromdate);
    							//console.log('tillDate:::' + tilldate);

    							if (dateToCompare >= fromdate && dateToCompare <= tilldate) {
    								return data.lines[i].value;
    							}
    						}
    					}
    					return 0;
    				}
    			}
    		},
    		getLinenPrice: function(startDate) {

    			if (this.serviceByLinen[0]) {
    				var data = this.serviceByLinen[0];
    				if (data.price) {
    					return data.price;
    				} else {
    					//console.log('startDate1::' + startDate);
    					//console.log('startDate2::' + new Date(startDate));
    					var dateToCompare = new Date(startDate);
    					if (data.lines) {
    						for (let i = 0; i < data.lines.length; i++) {

    							var fromdate = new Date(data.lines[i].fromdate);
    							var tilldate = new Date(data.lines[i].tilldate);
    							//console.log('fromDate:::' + fromdate);
    							//console.log('tillDate:::' + tilldate);

    							if (dateToCompare >= fromdate && dateToCompare <= tilldate) {
    								return data.lines[i].value;
    							}
    						}
    					}
    					return 0;
    				}
    			}
    		},

    		getCalculatedMandatoryPrice: function(season, price, fromdate) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			var cleaningPrice = this.getCleaningPrice(fromdate);
    			var bookingPrice = this.getBookingPrice(fromdate);
    			var linenPrice = this.getLinenPrice(fromdate);
    			if (linenPrice > 0 && this.beds > 0) {
    				//linenPrice = linenPrice * this.beds;
    				linenPrice = linenPrice * 2;
    			}

    			/*console.log('season::' + season);
    			console.log('rent::' + price);
    			console.log('claningPrice::' + cleaningPrice);
    			console.log('bookingPrice::' + bookingPrice);
    			console.log('linenPrice::' + linenPrice);*/
    			calcPrice = Number((price + cleaningPrice + bookingPrice + linenPrice) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			//console.log('fromYear::', fromYear, "Year::", year);
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>